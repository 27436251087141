import React from "react";
import { useTranslation } from "react-i18next";

export default function Directions(){
    const { t } = useTranslation();

    return(
        <section className="directions">
            <img src="./images/cubic.svg" className="directions-photo" alt=""/>
            <div className="container">
                <h3 className="directions-title">{t('activities')}</h3>
                <div className="directions-text">
                    <p>{t('home-activities-text1')}</p>
                    <p>{t('home-activities-text2')}</p>
                </div>
                <div className="directions-items">
                    <div className="directions-item">
                        <div className="directions-top">
                            <div className="directions-icon directions-icon__color1">
                                <img src="./images/directions-icon1.svg" className="directions-img" alt="#"/>
                            </div>
                            <div className="directions-bg">
                                <img src="./images/dots.png" alt="" />
                            </div>
                        </div>
                        <div className="directions-content">
                            <p className="directions-content__title">{t('home-activities-type-title1')}</p>
                            <p className="directions-content__text">{t('home-activities-type-text1')}</p>
                        </div>
                    </div>
                    <div className="directions-item">
                        <div className="directions-top">
                            <div className="directions-icon directions-icon__color2">
                                <img src="./images/directions-icon2.svg" className="directions-img" alt="#"/>
                            </div>
                            <div className="directions-bg">
                                <img src="./images/dots.png" alt="" />
                            </div>
                        </div>
                        <div className="directions-content">
                            <p className="directions-content__title">{t('home-activities-type-title2')}</p>
                            <p className="directions-content__text">{t('home-activities-type-text2')}</p>
                        </div>
                    </div>
                    <div className="directions-item">
                        <div className="directions-top">
                            <div className="directions-icon directions-icon__color3">
                                <img src="./images/directions-icon3.svg" className="directions-img" alt="#"/>
                            </div>
                            <div className="directions-bg">
                                <img src="./images/dots.png" alt="" />
                            </div>
                        </div>
                        <div className="directions-content">
                            <p className="directions-content__title">{t('home-activities-type-title3')}</p>
                            <p className="directions-content__text">{t('home-activities-type-text3')}</p>
                        </div>
                    </div>
                    <div className="directions-item">
                        <div className="directions-top">
                            <div className="directions-icon directions-icon__color4">
                                <img src="./images/directions-icon4.svg" className="directions-img" alt="#"/>
                            </div>
                            <div className="directions-bg">
                                <img src="./images/dots.png" alt="" />
                            </div>
                        </div>
                        <div className="directions-content">
                            <p className="directions-content__title">{t('home-activities-type-title4')}</p>
                            <p className="directions-content__text">{t('home-activities-type-text4')}</p>
                        </div>
                    </div>
                    <div className="directions-item">
                        <div className="directions-top">
                            <div className="directions-icon directions-icon__color5">
                                <img src="./images/directions-icon5.svg" className="directions-img" alt="#"/>
                            </div>
                            <div className="directions-bg">
                                <img src="./images/dots.png" alt="" />
                            </div>
                        </div>
                        <div className="directions-content">
                            <p className="directions-content__title">{t('home-activities-type-title5')}</p>
                            <p className="directions-content__text">{t('home-activities-type-text5')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src="./images/divider.png" className="directions-divider" alt=""/>
        </section>
    )
}

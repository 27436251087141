import React from "react";
import Insert from "./insert";
import Onas from "./Onas/onas";
import Technology from "./Technology/technology";
import Directions from "./Directions/directions";
import Draft from './Draft/draft'
import Partners from './Partners/partners'


export  default function HomePage() {
    return(
        <>
            <Insert />
            <Onas />
            <Technology />
            <Directions />
            <Draft />
            <Partners />
        </>
    )
}

import React from "react";
import { useTranslation } from "react-i18next";
import ListLi from './listLi'
import {Link} from "react-router-dom";
   
export default function Onas(){
    const { t } = useTranslation();
    return(
        <section className="onas">
            <div className="container">
                <div className="onas-items">
                    <div className="onas-item">
                        <h2 className="onas-head"> {t('home-about-tile')} </h2>
                        <p className="onas-title">{t('home-about-tile1')}</p>
                        <p className="onas-text">
                            {t('home-about-text')}
                        </p>
                        <ul className="onas-lists">
                            <ListLi
                                img = {<svg xmlns="http://www.w3.org/2000/svg" fill="#98ca48" width="22" height="22" fillRule="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                    </svg>}
                                text = {t('home-about-list1')}
                            />
                            <ListLi
                                img = { <svg xmlns="http://www.w3.org/2000/svg" fill="#98ca48" width="22" height="22" fillRule="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                    </svg> }
                                text = {t('home-about-list2')}
                            />
                            <ListLi
                                img = { <svg xmlns="http://www.w3.org/2000/svg" fill="#98ca48" width="22" height="22" fillRule="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                    </svg> }
                                text = {t('home-about-list3')}
                            />
                        </ul>
                        <Link className="btn" to="/about">
                            {t('more')}
                        </Link>
                    </div>
                    <div className="onas-item1">
                        <div className="onas-photo">
                            <img src="/images/onas1.jpg" className="onas-photo__img1" alt="#"/>
                            <img src="/images/onas2.jpg" className="onas-photo__img2" alt="#"/>
                            <img src="/images/onas3.jpg" className="onas-photo__img3" alt="#"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

import React from "react";
import { useTranslation } from "react-i18next";

export default function MyInfo() {
    const {t} = useTranslation()
    return (
        <section className="my">
            <div className="container">
                <h1 className="about-title">
                    {t('aboutUs')}
                </h1>
                <div className="about-text">
                    {t('aboutPage-text')}
                </div>
            </div>
        </section>
    )
}
import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Language from './language/language'
import paths from "./../../_helpers/paths";
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
    const { t } = useTranslation();
    const [activeMenu, setActiveMenu] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    let menu = [
        {
            text: 'aboutUs',
            url: paths.about
        },
        {
            text: 'projects',
            url: paths.portfolio
        },
        {
            text: 'contacts',
            url: paths.contacts
        },
    ]

    const [isFixed, serIsFixed] = useState(true);
    let location = useLocation();
    const [current, setCurrent] = useState('/')

    React.useEffect(() => {
        setCurrent(location.pathname)
    }, [location]);

    React.useEffect(() => {
        if (current === '/') {
            serIsFixed(false)
            setActiveMenu('')
        } else {
            serIsFixed(true)
        }
    }, [current])

    React.useEffect((e) => {
        window.addEventListener('scroll',function(event){
            if (window.pageYOffset >= window.outerHeight - 300) {
                serIsFixed(true)
            } else {
                serIsFixed(false)
            }
        }); 
    })

    const toggleMenu = (i) => {
        setActiveMenu(i)
        setIsOpen(!isOpen)

    }

    React.useEffect(() => {
        if (location.pathname === paths.about) {
            setActiveMenu(0)
        } else if (location.pathname === paths.portfolio) {
            setActiveMenu(1)
        } else if (location.pathname === paths.contacts) {
            setActiveMenu(2)
        }
    })

    const openPhoneMenu = () => {
        setIsOpen(!isOpen)
    }

    return(
        <header className={isFixed || current !== '/' ? 'header header1' : 'header'}>
            <div className="header-wrapper">
                <Link to = '/' className="header-logo">
                    <img src="/images/logo.png" alt="logo"/>
                </Link>
                <nav className={isOpen ? 'header-menus header-menus__active' : 'header-menus'}>
                    <ul className="header-menu">
                        {
                            menu.map((item, i) => {
                                return <li key = {i}>
                                    <Link to={item.url} className={activeMenu === i ? 'header-menu__active' : ''} onClick = {() => toggleMenu(i)}> {t(item.text)} </Link>
                                </li>
                            })
                        }
                    </ul>
                    <Language />
                </nav>
                <div className="phone-menu" onClick={openPhoneMenu}>
                    <MenuIcon style={{ fill: '#8cc138', fontSize: 35}}/>
                </div>
            </div>
        </header>
    )
}
import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "./../../Translate/i18n";

export default function Language() {
    let language = ['РУС', 'ҚАЗ', 'ENG'];
    const { i18n } = useTranslation();
    const myRef = React.useRef(null);

    const [languageActive, setLanguageActive] = React.useState(0)
    const [mobile, setMobile] = React.useState(false);
    const [activeMenu, setActiveMenu] = React.useState(0);
    const [isOpen, setIsOpen] = React.useState(false)
    const mobile_screen_size = 756;

    React.useEffect(() => (window.onresize = () => {
        if(window.innerWidth >= mobile_screen_size){
            setMobile(false);
        }else{
            setMobile(true);
        }
    }));

    const changleLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    const changeMenu = (number = 0) => {
        setActiveMenu(number)
        setLanguageActive(number)
        if (number === 0) {
            changleLanguage('ru')
        } else if (number === 1) {
            changleLanguage('kk')
        } else {
            changleLanguage('en')
        }
        setIsOpen(!isOpen)
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }
    
    React.useEffect(() => {
        if (i18next.language === 'kk') {setActiveMenu(1)}
        else if (i18next.language === 'en') {setActiveMenu(2)}
        else {setActiveMenu(0)}
    }, []);

    const handleClick = (e) => {
        if (!e.path.includes(myRef.current)) {
            setIsOpen(false);
        }
    };
   
    React.useEffect(() => {
        document.body.addEventListener('click', handleClick)
    },[]);
    
    return(
        <div className="header-language">
            {
                mobile ? 
                <ul className="header-language__menus">
                    {language.map((item, i) => <li key={i} className={languageActive === i ? "header-language__menu header-language__active" : 'header-language__menu'} onClick={() => changeMenu(i)}>{item}</li>)}
                </ul>
                :
                <>
                    <div className="header-language__current" onClick={toggleMenu} ref = {myRef}>
                        {language[activeMenu]} 
                            <span className={isOpen ? '' : 'header-language__menu-icon'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                </svg>
                            </span>
                    </div>
                    {
                        isOpen &&
                        <ul className="header-language__menus">
                            {language.map((item, i) => <li key={i} className="header-language__menu" onClick={() => changeMenu(i)}>{item}</li>)}
                        </ul>
                    }
                </> 
            }
        </div>
    )
}

import React from "react";

export default function TechnologyItem({img, classes}) {

    return(
        <div className = {classes ? `technology-item ${classes}` : 'technology-item'}>
            <img src = {img} alt="#" className="technology-item__img"/>
        </div>
    )
}

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { range } from 'lodash'

export default function Portfolio() {
    const { t } = useTranslation();

    return(
        <main className="projects">
            <div className="container">
                <h2 className="projects-title">{t('projects')}</h2>
            </div>
            <section className="projects-wrapper pt-50">
                <img src="./images/cubic1.svg" className="projects-cubic__right" alt="#" />
                <div className="container">
                    <div className="projects-item">
                        <p className="projects-item__title">{t('projectsPage_section0')}</p>
                        <p className="projects-item__text">{t('projectsPage_section0_paragraph0')}</p>
                        <p className="projects-item__title1">{t('projectsPage_section0_paragraph1')}</p>
                        <ul className="projects-lists">
                            <li className="projects-list"> {t('projectsPage_section0_paragraph1_item0')} </li>
                            <li className="projects-list"> {t('projectsPage_section0_paragraph1_item1')} </li>
                        </ul>
                        <Link to="/portfolio/1" className="projects-btn">
                            {t('more')}
                        </Link>
                        <img src="./images/divider.png" alt="#" className="projects-line"/>
                    </div>
                </div>
            </section>
            <section className="projects-wrapper">
                <img src="./images/cubic1.svg" className="projects-cubic__right" alt="#" />
                <div className="container">
                    <div className="projects-item">
                        <p className="projects-item__title">{t('projectsPage_section4')}</p>
                        <p className="projects-item__title1">{t('projectsPage_section4_paragraph1')}</p>
                        <ul className="projects-lists">
                            <li className="projects-list">{t('projectsPage_section4_paragraph1_item0')}</li>
                            <li className="projects-list">{t('projectsPage_section4_paragraph1_item1')}</li>
                            <li className="projects-list">{t('projectsPage_section4_paragraph1_item2')}</li>
                            <li className="projects-list">{t('projectsPage_section4_paragraph1_item3')}</li>
                        </ul>
                        <Link to="/portfolio/2" className="projects-btn">
                            {t('more')}
                        </Link>
                        <img src="./images/divider.png" alt="#" className="projects-line"/>
                    </div>
                </div>
            </section>
            <section className="projects-wrapper">
                <img src="./images/cubic1.svg" className="projects-cubic__right" alt="#" />
                <div className="container">
                    <div className="projects-item">
                        <p className="projects-item__title">{t('projectsPage_section6')}</p>
                        <p className="projects-item__text">{t('projectsPage_section6_paragraph0')}</p>
                        <Link to="/portfolio/3" className="projects-btn">
                            {t('more')}
                        </Link>
                        <img src="./images/divider.png" alt="#" className="projects-line"/>
                    </div>
                </div>
            </section>
        </main>
    )
}

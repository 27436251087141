import React from "react";
import { useTranslation } from "react-i18next";
import TechnologyItem from './TechnologyItem';
import Title from './../../../components/title'

export default function Technology() {
    const { t } = useTranslation();

    return(
        <section className="technology">

            <div className="container">
                <Title text = {t('technologyStack')} />

                <div className="technology-items">
                    <TechnologyItem img = {'./images/technology/sap.png'} classes = 'technology-item__top technology-item__left' />
                    <TechnologyItem img = {'./images/technology/html.svg'} classes = 'technology-item__top' />
                    <TechnologyItem img = {'./images/technology/java.svg'} classes = 'technology-item__top' />
                    <TechnologyItem img = {'./images/technology/kotlin.svg'} classes = 'technology-item__top' />
                    <TechnologyItem img = {'./images/technology/react.svg'} classes = 'technology-item__top' />
                    <TechnologyItem img = {'./images/technology/vue.svg'} classes = 'technology-item__top' />
                    <TechnologyItem img = {'./images/technology/bootstrap.svg'} classes = 'technology-item__left' />
                    <TechnologyItem img = {'./images/technology/oracle.svg'} />
                    <TechnologyItem img = {'./images/technology/mongodb.svg'} />
                    <TechnologyItem img = {'./images/technology/microsoft-sql-server.svg'} />
                    <TechnologyItem img = {'./images/technology/css.svg'} />
                    <TechnologyItem img = {'./images/technology/material-ui.svg'} />
                    <TechnologyItem img = {'./images/technology/redux.svg'} classes = 'technology-item__left'/>
                    <TechnologyItem img = {'./images/technology/power-bi.svg'} />
                    <TechnologyItem img = {'./images/technology/postgresql.svg'} />
                    <TechnologyItem img = {'./images/technology/spring.svg'} />
                    <TechnologyItem img = {'./images/technology/semantic-ui.svg'} />
                    <TechnologyItem img = {'./images/technology/javascript.svg'} />
                </div>
            </div>
        </section>
    )
}

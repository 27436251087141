import React from "react";

    
export default function ListLi({img, text}){
    return(
        <li className="onas-list">
            <span>
               {img}
            </span>
            {text}
        </li>
    )
}

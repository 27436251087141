import React from "react";
import { useTranslation } from "react-i18next";
import Team from './team';
import Title from './../../../components/title';

export default function Teams(){
    const {t} = useTranslation()

    return (
        <section className="team">
            <div className="container">
                <Title text = {t('ourTeam')} />
                <div className="team-items">
                    <Team img="./images/team1.svg" text={t('ourTeam1')} />
                    <Team img="./images/team2.svg" text={t('ourTeam2')} />
                    <Team img="./images/team3.svg" text={t('ourTeam3')} />
                    <Team img="./images/team4.svg" text={t('ourTeam4')} />
                    <Team img="./images/team5.svg" text={t('ourTeam5')} />
                    <Team img="./images/team6.svg" text={t('ourTeam6')} />
                </div>
            </div>
        </section>
    )
}
import React from "react";
import { useTranslation } from "react-i18next";
import Benefits from './benefits'

export default function Insert({contentText}){
    const { t } = useTranslation();
    return(
        <section className="insert">
            <div className="insert-bg">
                <img src="/images/home-bg11.png" className="insert-bg__img" alt="#" />
                <div className="insert-bg__color"></div>
            </div>
            <div className="insert-content">
                <h1>MN PARTNERS</h1>
                <p className="insert-content__text">{t('insert-h2')}</p>
            </div>
            <div className="insert-bottom">
                <div className="container">
                    <div className="insert-items">
                        <Benefits title='10+' text={t('insert-text1')}/>
                        <Benefits title='24/7' text={t('insert-text2')}/>
                        <Benefits title='25+' text={t('insert-text3')}/>
                        <Benefits title='25+' text={t('insert-text4')}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
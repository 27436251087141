import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        supportedLngs: ['ru', 'en', 'kk'],
        fallbackLng: "ru",
        interpolation: {
            escapeValue: false
        },
        react: { 
            useSuspense: false //   <---- this will do the magic
        }
    });

  export default i18n;
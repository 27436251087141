import React from "react";
import { useTranslation } from "react-i18next";
import ReplyIcon from '@material-ui/icons/Reply';
import { range } from 'lodash';
import PortfolioList from './PortfolioList';
import ProjectTitle from './projectTitle';

export default function Portfolio6() {
    const { t } = useTranslation();
    return (
        <main className="projects">
            <div className="container">
                <ProjectTitle text = {t('projectsPage_section6')} />
            </div>
            <section className="projects-wrapper pt-50">
                <img src="./../images/cubic1.svg" className="projects-cubic__right" alt="#" />
                <div className="container">
                    <div className="project-item">
                        <p className="projects-item__text">{t('projectsPage_section6_paragraph0')}</p>
                    </div>
                    <p className="projects-item__title1 mb-20">{t('projectsPage_section6_paragraph1')}</p>

                        <div className="projects-lists">
                            {
                                range(0, 3).map(row1 => (<PortfolioList key={row1} text = {t(`projectsPage_section6_paragraph1_item${row1}`)} />
                                ))
                            }
                        </div>
                </div>
                
            </section>
        </main>
    )
}
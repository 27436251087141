import React from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-items">
                    <div className="footer-item">
                        <div className="footer-logo">
                            <img src="./../images/logo.png" className="footer-logo__img"/>
                        </div>
                    </div>
                    <div className="footer-item">
                        
                    </div>
                    <div className="footer-item">
                        <p className="footer-title"> {t('contacts')} </p>
                        <ul className="footer-contacts">
                            <li className="footer-contact"><img src="./../images/geo-alt.svg" className="footer-icon" alt="#"/>{t('address')}</li>
                            <li className="footer-contact"><img src="./../images/email.svg" className="footer-icon" alt="#"/>{t('email')}</li>
                            <li className="footer-contact" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="./../images/telephone.svg" className="footer-icon" alt="#" style={{marginTop: '-7px'}}/>
                                <div>
                                    <p>{t('phone')}</p>
                                    <p>{t('phone2')}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <ul className="footer-menus">
                        <li className="footer-menu"><Link to="./about">{t('aboutUs')}</Link></li>
                        <li className="footer-menu"><Link to="./portfolio">{t('projects')}</Link></li>
                        <li className="footer-menu"><Link to="./contacts">{t('contacts')}</Link></li>
                    </ul>
                    <p className="footer-bottom__text">© {`${t('footer_nameСompany')} ${moment().format('YYYY')}`} </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

import React from "react";

export default function Benefits({title, text}){
    return(
        <div className="insert-item">
            <div>
                <p className="insert-item__sup">
                    {title}
                </p>
                <p className="insert-item__sub">
                    {text}
                </p>
            </div>
        </div>
    )
}
import React from "react";
import { useTranslation } from "react-i18next";
import Sectors from './sectors/sectors'
import Teams from './teams/teams'
import MyInfo from './myInfo/myInfo'
export default function AboutPage({contentText}){
    const {t} = useTranslation()

    return (
        <main className="about">
            <MyInfo />
            <Sectors />
            <Teams />
        </main>
    )
}
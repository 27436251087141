import React from "react";
import { useTranslation } from "react-i18next";
import { range } from 'lodash'
import HeightIcon from '@material-ui/icons/Height';
import PortfolioList from './PortfolioList'
import ProjectTitle from './projectTitle';
import i18next from "../../common/Translate/i18n";

export default function Portfolio4() {
    const { t } = useTranslation();
    console.log(i18next.language)
    return(
        <main className="projects">
            <div className="container">
                <ProjectTitle text = {t('projectsPage_section4')} />
            </div>
            <section className="projects-wrapper pt-50">
                <img src="./../images/cubic1.svg" className="projects-cubic__right" alt="#" />
                <div className="container">
                    <p className="projects-item__title1 mb-20">{t('projectsPage_section4_paragraph1')}</p>
                    <div className="projects-lists">
                        {
                            range(0, 5).map(row1 => (
                                <PortfolioList key = {row1} text = {t(`projectsPage_section4_paragraph1_item${row1}`)} />
                            ))
                        }
                    </div>
                    <p className="projects-item__title1 mb-20">{t('projectsPage_section4_paragraph2')}</p>
                    <div className="projects-lists">
                        {
                            range(0, 8).map(row2 => (
                                <PortfolioList key={row2} text={t(`projectsPage_section4_paragraph2_item${row2}`)} />
                            ))
                        }
                    </div>
                </div>
            </section>
            <section className="projects1-block1">
                <div className="container">
                    <p className="project-item__title">{t('projectsPage_section4_chart_title')}</p>
                    <figure className={'project4-chart'}>
                        {
                            i18next.language === 'ru' && <img src={'./../images/big-chart-ru.png'} className="project4-chart__img" alt="Схема"/>
                        }
                        {
                            i18next.language === 'kk' && <img src={'./../images/big-chart-kk.png'} className="project4-chart__img" alt="Схема"/>
                        }
                        {
                            i18next.language === 'en' && <img src={'./../images/big-chart-en.png'} className="project4-chart__img" alt="Схема"/>
                        }
                    </figure>
                    <p className="projects-item__title1 mb-20">{t('projectsPage_section4_paragraph3')}</p>
                    <div className="projects-lists">
                        {
                            range(0, 7).map(row3 => (
                                <PortfolioList key={row3} text = {t(`projectsPage_section4_paragraph3_item${row3}`)} />
                            ))
                        }
                    </div>
                </div>  
            </section>
            <section className="projects4-block3">
                <div className="container">
                    <div className="projects4-block3__border">
                        <p className="projects4-block3__text">{t('projectsPage_section4_image1_title')}</p>
                        <div className="projects4-block3__items">
                            <figure className={'projects4-block3__item'}>
                                <img src={'./../images/letter.svg'} className="projects4-block3__img" alt="Схема"/>
                            </figure>
                            <figure className={'projects4-block3__item'}>
                                <img src={'./../images/checklist.svg'} className="projects4-block3__img" alt="Схема"/>
                            </figure>
                            <figure className={'projects4-block3__item'}>
                                <img src={'./../images/charts.svg'} className="projects4-block3__img" alt="Схема"/>
                            </figure>
                            <figure className={'projects4-block3__item'}>
                                <img src={'./../images/hand.svg'} className="projects4-block3__img" alt="Схема"/>
                            </figure>
                        </div>
                        
                    </div>
                    <div className="HeightIcon">
                        <HeightIcon style={{ fill: '#8cc138', fontSize: 60 }}/>
                    </div>
                    <div className="projects4-block3__grids">
                        <figure className={'projects4-block3__grid'}>
                            <img src={'./../images/man-1.svg'} className="projects4-block3__photo" alt="Схема"/>
                        </figure>
                        <figure className={'projects4-block3__grid'}>
                            <img src={'./../images/woman.svg'} className="projects4-block3__photo" alt="Схема"/>
                        </figure>
                        <figure className={'projects4-block3__grid'}>
                            <img src={'./../images/man-2.svg'} className="projects4-block3__photo" alt="Схема"/>
                        </figure>
                    </div>
                </div>
            </section>
        </main>
    )
}
import React from "react";
import { useTranslation } from "react-i18next";
import Sector from './sector'
export default function Sectors(){
    const {t} = useTranslation()

    return (
        <section className="sectors">
            <div className="container">
                <h2 className="sector-title">{t('Industries')}</h2>
                <p className="sector-text">
                    {t('Industries-text')}
                </p>
                    <div className="sector-items">
                        <Sector text={t('Industries1')} img = './images/sector-icon1.svg' />
                        <Sector text={t('Industries2')} img = './images/sector-icon2.svg' />
                        <Sector text={t('Industries3')} img = './images/sector-icon3.svg' />
                        <Sector text={t('Industries4')} img = './images/sector-icon4.svg' />
                        <Sector text={t('Industries5')} img = './images/sector-icon5.svg' />
                        <Sector text={t('Industries6')} img = './images/sector-icon6.svg' />
                    </div>
            </div>
        </section>
    )
}
import React from "react";
import CircleIcon from '@mui/icons-material/Circle';

export default function PortfolioList({text}) {
    return(
        <p className="portfolio-list mb-20">
            <CircleIcon style={{ fill: '#8cc138', fontSize: 10, marginTop: 5 }} />
            <span>
                {text}
            </span>
        </p>
    )
}
import React from "react";
import { useTranslation } from "react-i18next";
import Partner from './partner'
import Title from './../../../components/title'
export default function Partners() {
    const { t } = useTranslation();

    return (
        <section className="partners">
            <div className="container">
                <Title text = {t('partners')} />
                <div className="partners-items">
                    <Partner img='./images/GeneralnayaProkuraturaRespublikiKazahstan.png' />
                    <Partner img='./images/Karachaganak.png' />
                    <Partner img='./images/Kazatomprom.svg' />
                    <Partner img='./images/RoyalDutchShell.svg' />
                </div>
            </div>
        </section>
    )
}

import React from "react";

export default function Partner({img}) {

    return (
        <div className="partners-item">
            <img src={img} className="partners-item__img" alt="#" />
        </div>
    )
}

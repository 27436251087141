import React from "react";
import { useTranslation } from "react-i18next";
import ReplyIcon from '@material-ui/icons/Reply';
import { range } from 'lodash';
import PortfolioList from './PortfolioList';
import ProjectTitle from './projectTitle';

export default function Portfolio1() {
    const { t } = useTranslation();
    return (
        <main className="projects">
            <div className="container">
                <ProjectTitle text = {t('projectsPage_section0')} />
            </div>
            <section className="projects-wrapper pt-50">
                <img src="./../images/cubic1.svg" className="projects-cubic__right" alt="#" />
                <div className="container">
                    <div className="project-item">
                        <p className="projects-item__text">{t('projectsPage_section0_paragraph0')}</p>
                        <p className="projects-item__title1 mb-20">{t('projectsPage_section0_paragraph1')}</p>
                        <div className="projects-lists">
                            {
                                range(0, 3).map(row => ( <PortfolioList key={row} text = {t(`projectsPage_section0_paragraph1_item${row}`)} /> ))
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="projects1-block1">
                <div className="container">
                    <p className="project-item__title">{t('projectsPage_section0_paragraph2')}</p>
                    <div className="projects1-block1_wrappes">
                        <figure className="projects1-photo">
                            <img src='./../images/database.jpg' width="180px" alt="services" className="projects1-photo__img"/>
                        </figure>
                        <ul className="projects1-lists">
                            {
                                 range(0, 17).map(row => (
                                    <li key = {row} className="projects1-list">
                                      <ReplyIcon style={{ fill: '#8cc138' }}/>
                                      <span className="projects1-list__text">{t(`projectsPage_section0_item${row}`)}</span>
                                    </li>
                                  ))
                            }
                        </ul>
                    </div>
                </div>  
            </section>
            <section className="projects1-block2">
                <div className="container">
                    <div className="projects1-block2_wrappers">
                        <div className="projects1-block2_wrapper">
                            <p className="projects1-block2__title">{t('projectsPage_section0_paragraph4')}</p>
                            <ul className="projects1-block2__lists">
                                <li className="projects1-block2__list">{t('projectsPage_section0_paragraph4_item0')}</li>
                                <li className="projects1-block2__list">{t('projectsPage_section0_paragraph4_item1')}</li>
                            </ul>
                        </div>
                        <div className="projects1-block2_wrapper">
                            <figure className="projects1-block2_wrapper-photo">
                                <img src={'./../images/government.png'} height="100px" alt="organizations"/>
                                <figcaption className="projects1-block2_wrapper-text">{t('projectsPage_section0_image2')}</figcaption>
                            </figure>
                        </div>
                        <div className="projects1-block2_wrapper">
                            <figure className="projects1-block2_wrapper-photo">
                                <img src={'./../images/users2.png'} height="100px" alt="users"/>
                                <figcaption className="projects1-block2_wrapper-text">{t('projectsPage_section0_image1')}</figcaption>
                            </figure>
                        </div>
                    </div>
                </div>  
            </section>
        </main>
    )
}